import { Fragment } from "react";
import "./App.css";
import "react-quill/dist/quill.snow.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DynamicForm5 from "./DynamicForm5";
import EmailBuilder from "./EmailBuilder";
import MyQuillEditor from "./quill/MyQuillEditor";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Home";
import CategoryBuilder from "./CategoryBuilder";
import SwipeableTemporaryDrawer from "./Demo";
import MiniDrawer from "./SlideHeader";
import DragDroppable from "./DragDroppable";
import RegistrationPage from "./registration/RegistrationPage";
import ExportSymposium from "./exportSymposium";
import MyEmailTemplate from "./registration/email/MyEmailTemplate";
import RegisrationQr from "./registration/RegistrationQr";
import { RegFormProvider } from "./registration/context/RegFormContext";
function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/email" element={<EmailBuilder />} />
          <Route path="/editor" element={<DynamicForm5 />} />
          <Route path="/test-dnd" element={<DragDroppable />} />
          <Route
            path="/visitor-registration"
            element={
              <RegFormProvider>
                <RegistrationPage />
              </RegFormProvider>
            }
          />
          <Route path="/reg-qr/:linkCode" element={<RegisrationQr />} />
          <Route path="/export-symposium-users" element={<ExportSymposium />} />
          <Route path="/demo" element={<SwipeableTemporaryDrawer />} />
          <Route path="/slide-draw" element={<MiniDrawer />} />
          <Route path="/category-builder" element={<CategoryBuilder />} />
          <Route path="/email-template-builder" element={<MyQuillEditor />} />
          <Route
            path="/imtex-2025-visitor-email"
            element={<MyEmailTemplate />}
          />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
