import React, { useContext, useEffect, useState } from "react";
import { TextField, Autocomplete, CircularProgress, Box } from "@mui/material";
import useCities from "./hooks/useCities";
import FormLabel from "../FormLabel";
import { RegFormContext } from "../context/RegFormContext";

const CitySelect = ({ form }) => {
  const context = useContext(RegFormContext);
  if (!context) {
    throw new Error(
      "ComponentUsingFormContext must be used within a RegistrationFormProvider"
    );
  }

  const { formData, updateFormData } = context;

  const { cities, loading } = useCities(
    formData["country"]?.code || null,
    formData["stateRegion"]?.code || null
  );

  const [selectedCity, setSelectedCity] = useState(formData[form.id] || null);

  useEffect(() => {
    updateFormData(form.id, selectedCity);
  }, [selectedCity]);

  return (
    <Box>
      <Autocomplete
        size="small"
        options={cities || []}
        getOptionLabel={(option) => option.name || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label={<FormLabel label={form.label} required={form.required} />}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        value={formData[form.id]}
        onChange={(event, newValue) => setSelectedCity(newValue)}
      />
    </Box>
  );
};

export default CitySelect;
