import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormComponent from "./FormComponent";
import { HEADER } from "./constants";
import "./Registration.css";
import BannerSlider from "./BannerSlider";
import RegFooter from "./RegFooter";
import { LoadingButton } from "@mui/lab";
import bgImg from "./bg_img.png";
import { API_ENDPOINT } from "./myconst";
import { toast } from "react-toastify";
import TermsAndConditions from "./TermsAndConditions";
import axios from "axios";
import { getDbHeader, toastError } from "../utils/util";
import { useNavigate } from "react-router-dom";
import { RegFormContext } from "./context/RegFormContext";
import useRegistrationSettings from "./hooks/useRegistrationSettings";
import MuPb from "../widgets/MuPb";
const RegistrationPage = () => {
  const navigate = useNavigate();
  const [registering, setRegistering] = useState(false);
  const { formData, updateFormData, initForm } = useContext(RegFormContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  const { data: formConfig, error, loading } = useRegistrationSettings();

  useEffect(() => {
    if (formConfig) {
      initForm(formConfig);
      updateFormData("preview", false);
      updateFormData("termsAccepted", false);
    }
  }, [formConfig]);

  const getHeight = () => {
    if (isSmallScreen) return "150px";
    if (isMediumScreen) return "200px";
    if (isLargeScreen) return "300px";
  };
  const getVariant = () => {
    if (isSmallScreen) return "h6";
    if (isMediumScreen) return "h5";
    if (isLargeScreen) return "h4";
  };
  function validateForm(formData, formConfig) {
    const errors = [];

    formConfig.items.forEach((section) => {
      section.fields.forEach((field) => {
        const { id, required, valueType, label } = field;
        const value = formData[id];

        if (required) {
          // Check for empty values
          if (
            (valueType === "string" && !value) ||
            (valueType === "object" && Object.keys(value).length === 0) ||
            (valueType === "array" && value.length === 0)
          ) {
            errors.push({ id, message: `${label} is required.` });
          }
        }

        // Additional validations for specific types
        if (valueType === "string" && typeof value !== "string") {
          errors.push({ id, message: `${label} must be a string.` });
        }

        if (valueType === "array" && !Array.isArray(value)) {
          errors.push({ id, message: `${label} must be an array.` });
        }

        if (valueType === "object" && typeof value !== "object") {
          errors.push({ id, message: `${label} must be an object.` });
        }

        // Additional validations for specific field types
        if (field.type === "EMAIL" && value && !validateEmail(value)) {
          errors.push({ id, message: `Invalid email format in ${label}.` });
        }

        if (field.type === "PHONE" && value && !validatePhoneNumber(value)) {
          errors.push({
            id,
            message: `Invalid phone number format in ${label}.`,
          });
        }

        if (field.type === "COUNTRY" && value && !validateCountry(value)) {
          errors.push({
            id,
            message: `Invalid country selection in ${label}.`,
          });
        }
      });
    });

    return errors;
  }
  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  function validatePhoneNumber(phone) {
    const trimmedPhone = phone.trim();
    const indianPhonePattern = /^\+91\s?(\d{5}\s?\d{5})$/;
    const generalPhonePattern = /^\+?\d+(\s\d+)*$/;

    if (trimmedPhone.startsWith("+91")) {
      return indianPhonePattern.test(trimmedPhone);
    } else {
      return generalPhonePattern.test(trimmedPhone);
    }
  }
  function validateCountry(country) {
    return (
      typeof country === "object" &&
      "name" in country &&
      "code" in country &&
      "flag" in country
    );
  }
  const handleFormSubmit = async () => {
    const errors = validateForm(formData, formConfig);
    if (errors.length > 0) {
      const error = errors[0];
      let field = document.getElementById(error.id);
      field.scrollIntoView({ behavior: "smooth", block: "center" });
      field.style.border = "1px solid red";
      setTimeout(() => {
        field.style.border = "";
      }, 3000);
      toast.error(`Field ${error.id} ${error.message}`);
    } else {
      if (!formData["preview"]) {
        updateFormData("preview", true);
        let field = document.getElementById("title");
        field.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        //submit data
        setRegistering(true);
        try {
          const response = await axios.post(
            `${API_ENDPOINT}user/visitor-register`,
            formData,
            getDbHeader()
          );
          let reg_id = response.data.reg_id;
          navigate(`/reg-qr/${reg_id}`);
        } catch (error) {
          toastError(error);
        } finally {
          setRegistering(false);
        }
      }
    }
  };
  if (loading) {
    return <MuPb />;
  }

  return (
    <Box
      sx={{
        backgroundColor: "rgb(237, 241, 242)",
        minHeight: "100vh",
        width: "100vw",
        overflow: "auto",
        position: "fixed",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: getHeight(),
          top: 0,
          position: "absolute",
          backgroundImage: `url(${bgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
        }}
      >
        <Box maxWidth="md" sx={{ ml: "auto", mr: "auto" }} p={2}>
          <Box className="header-con-xcch" sx={{ textAlign: "center", mb: 4 }}>
            <img
              src={HEADER}
              alt="Main Header"
              style={{ width: "100%", height: "auto", marginBottom: "20px" }}
            />
            <BannerSlider />
          </Box>
          <Typography
            variant={getVariant()}
            component="h2"
            align="center"
            fontWeight={600}
            gutterBottom
            sx={{ color: "#E60008" }}
          >
            VISITOR REGISTRATION
          </Typography>
          <FormComponent formConfig={formConfig} />
          {formData.preview && <TermsAndConditions />}
          <Box gap={2} display={"flex"} justifyContent={"center"} mt={2}>
            {formData.preview && (
              <LoadingButton
                onClick={() => updateFormData("preview", false)}
                variant="outlined"
                sx={{ width: 150 }}
              >
                Edit
              </LoadingButton>
            )}
            <LoadingButton
              loading={registering}
              disabled={formData.preview && !formData.termsAccepted}
              variant="contained"
              sx={{ bgcolor: "#2D545E", width: formData.preview ? 150 : 200 }}
              onClick={handleFormSubmit}
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
        <RegFooter />
      </Box>
    </Box>
  );
};

export default RegistrationPage;
