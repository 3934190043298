import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./BannerSlider.css";
import { Box } from "@mui/material";
import { ADS } from "./constants";

export default function BannerSlider() {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Box className="reg-img-con">
      <Slider {...sliderSettings}>
        {ADS.map((ad) => (
          <div>
            <img src={ad} />
          </div>
        ))}
      </Slider>
    </Box>
  );
}
